import logo from "./imgs/service-lux-logo.svg";
import icontitle from "./imgs/signpng.svg";
import ic_app from "./imgs/ic_launcher.png";
import ic_file from "./imgs/fileic.svg";
import file_app from "./file/app-release.apk";
import imgfordialog from "./imgs/vector17.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="entry-page">
        <header className="header">
          <a href="https://service-lux.com.ua/">
            <img src={logo} />
          </a>
        </header>
        <div className="warning-message">
          <div className="wr-main-title">
            <h1 className="main-title">Уппсс...!</h1>
          </div>
          <div className="wr-mini-dialog-window">
            <img
              className="small-icon-to-dialog-window"
              id="img-dis"
              src={imgfordialog}
              alt="small icon for dialog window"
            />
            <div className="wr-mini-dialog-window__entry">
              Через певні незручності, доступ до завантаження застосунка на
              платформі Google Play Маркет тимчасово обмеженнно, тому просимо у
              вас вибачення. Посилання на <b>скачування</b> самого{" "}
              <b>застосунка</b>, залишаємо у вікні <b>нижче</b>
            </div>
          </div>
        </div>
        <div className="wr__container-app-download">
          <div className="container-app-download">
            <div className="flame-app">
              <img src={ic_app} alt="icon app" />
              <div className="title-app">Сервис Люкс</div>
            </div>
            <div className="flame-download">
              <img src={ic_file} alt="icon download" />
              <span>
                <a href={file_app} download>
                  Завантажити
                </a>{" "}
              </span>
            </div>
            <div className="flame-instruction">
              <h3><b>Інструкція до завантаження</b></h3>
              <ol>
                <li>У налуштуваннях браузера буде пункт <b>Завантаженя</b>, натисніть на нього</li>
                <li>У переліку з заваженних файлів буде файл з назвою накшталт <b>app-release.c15e3fa3309e22f0e10b.apk</b>, натисніть на нього один раз </li>
                <li>Далі ваш телефон запронує його завантажити, в залежності від моделі вашого телефону підтвердіть згоду навіть, якщо ваш телефон буде давати попереджання про "небезбеку", то ігноруйте це</li>
              </ol>
              <h4>Якщо ваш телефон блокує завантаженя самого файла, будь ласка зайдіть у налаштування та надайте доступ на завантаженя невідомих застосунків </h4>
            </div>
          </div>
        </div>

        <div className="wr__container-app-download">
          <div className="container-app-download">
            <b>Виникли питання?</b> <br/>
            Звертайтесь за поштою <a href="mailto:service_luks@ukr.net">service_luks@ukr.net</a>
            </div>
          </div>
      </div>
    </div>
  );
}

export default App;
